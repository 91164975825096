<template>
    <div class="blueBg">
        <POS></POS>
        <!-- <POSV2></POSV2> -->
        <!-- <POSV3></POSV3> -->
    </div>
</template>

<script>
import POS from '@/components/POS.vue';
// import POSV2 from '@/components/POSV2.vue'
// import POSV3 from '@/components/POSV3.vue'

export default {
    name: "PosView",
    components: {
        POS,
        // POSV2,
        // POSV3,
    }

};
</script>

<style scoped>
.blueBg {
    background-color: #6498d0;
    padding-top: 90px;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}
</style>
