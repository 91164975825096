import Vuex from "vuex";
const baseUrl = process.env.VUE_APP_DOMAIN;
// const domain = process.env.VUE_APP_LOCAL_ONLY == "true" ? "https://coinsnap.os24.work" : 'https://coinsnap.io';
const aat = process.env.VUE_APP_LOCAL_ONLY == "true" ? '' : ''
const creds = process.env.VUE_APP_LOCAL_ONLY == "true" ? 'omit' : 'omit'
function getCookie() {
  return ''
  // const cookies = document.cookie.split(';');
  // for (let i = 0; i < cookies.length; i++) {
  //   const cookie = cookies[i].trim();
  //   if (cookie.startsWith(name + '=')) {
  //     return cookie.substring(name.length + 1);
  //   }
  // }
}

async function getApiCall(hook, json = true) {
  // if (!hook) hook = payload?.hook
  const res = await fetch(`${baseUrl}${hook}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json", "admin-access-token": aat, "cn-token": getCookie('cn-token'),
      credentials: creds,
    }
  });
  if (json) {
    const resJSON = await res.json();
    return resJSON;
  }
  return res
}

async function postApiCall(hook, payload = {}, json, url) {
  try {
    if (!url) url = baseUrl;
    let res = await fetch(`${url}${hook}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      credentials: creds,
      body: JSON.stringify(payload),
    });
    if (res.status == 404) {
      return null;
    }
    res = json ? await res.json() : res;
    return res;
  } catch (e) {
    console.log(e);
  }
}

async function putApiCall(hook, payload = {}) {
  const res = await fetch(`${baseUrl}${hook}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json", "admin-access-token": aat, "cn-token": getCookie('cn-token'), },
    credentials: creds,
    body: JSON.stringify(payload),
  });
  if (res.status == 404) {
    return null;
  }
  return res;
}

export default new Vuex.Store({
  mutations: {
  },
  actions: {
    async getApiCallAction(context, payload) {
      const res = await getApiCall(payload.hook, payload.json)
      return res;
    },
    async postApiCallAction(context, payload) {
      const res = await postApiCall(payload.hook, payload.body, payload.json, payload.url, payload.xApiKey)
      return res;
    },
    async putApiCallAction(context, payload) {
      const res = await putApiCall(payload.hook, payload.body)
      return res;
    },
  },

  modules: {},
});
