<template>

  <head>
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.13.0/css/all.css">
  </head>
  <router-view />
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.logo {
  height: 100px;
}

.logoArea {
  height: 60px;
}

.logoAreaUnder {
  margin-top: -10px;
  height: 30px
}

</style>
